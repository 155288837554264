import {Button, SpaceBetween, Spinner} from "@cloudscape-design/components";
import React, {useState} from "react";
import {RegistrationData} from "../../../data/FormData";
import {NewAccountPageTrigger, PagesEnum} from "./Utils/PageTrigger";
import {binaryOptions, phoneList} from "./Questions/questionOptions";
import SingleSelectComponent from "../../../components/SingleSelect";
import {TextInput} from "../../../components/TextInput";
import {validateInput} from "../../../utility-function/InputValidation";

export function QuestionnaireSimplePage(): JSX.Element {
    const [success, setSuccess] = useState<boolean>(false);
    const [submitWaiting, setSubmitWaiting] = useState<boolean>(false);
    const [submitFailed, setSubmitFailed] = useState<boolean>(false);

    const [firstName, setFirstName] = useState(RegistrationData.instance.firstName)
    const [showFirstNameMessage, setShowFirstNameMessage] = useState<boolean>(false)
    const [lastName, setLastName] = useState(RegistrationData.instance.lastName)
    const [showLastNameMessage, setShowLastNameMessage] = useState<boolean>(false)

    const [takeBloodPressureMedicationValue, setTakeBloodPressureMedicationValue] = useState<string>(RegistrationData.instance.bloodPressureMedication);
    const [showBPMedicationMessage, setShowBPMedicationMessage] = useState<boolean>(false);

    const [takeDiabetesMedicationValue, setTakeDiabetesMedicationValue] = useState<string>(RegistrationData.instance.dmMedication);
    const [showDMMedicationMessage, setShowDMMedicationMessage] = useState<boolean>(false);

    const [phoneModel, setPhoneModel] = useState<'Apple' | 'Android' | ''>(RegistrationData.instance.phoneType);
    const [showPhoneModelMessage, setShowPhoneModelMessage] = useState<boolean>(false);

    const [deviceType, setDeviceType] = useState<'Apple Watch' | 'Fitbit' | 'No' | 'opt-out' | ''>(RegistrationData.instance.deviceType);
    const [showDeviceTypeMessage, setShowDeviceTypeMessage] = useState<boolean>(false);

    const [bloodPressureMonitor, setBloodPressureMonitor] = useState<string>(RegistrationData.instance.bloodPressureMonitor);
    const [showBloodPressureMonitorMessage, setShowBloodPressureMonitorMessage] = useState<boolean>(false);

    const [glucometer, setGlucometer] = useState<string>(RegistrationData.instance.glucometer);
    const [showGlucometerMessage, setShowGlucometerMessage] = useState<boolean>(false);

    const [address, setAddress] = useState<string>(RegistrationData.instance.address);
    const [showAddressMessage, setShowAddressMessage] = useState<boolean>(false);

    const [addressCity, setAddressCity] = useState<string>(RegistrationData.instance.addressCity);
    const [showAddressCityMessage, setShowAddressCityMessage] = useState<boolean>(false);

    const [addressState, setAddressState] = useState<string>(RegistrationData.instance.addressState);
    const [showAddressStateMessage, setShowAddressStateMessage] = useState<boolean>(false);

    const [addressZipcode, setAddressZipcode] = useState<string>(RegistrationData.instance.addressZipcode);
    const [showAddressZipcodeMessage, setShowAddressZipcodeMessage] = useState<boolean>(false);

    function gotoPage(page: PagesEnum) {
        if (NewAccountPageTrigger.instance.setNewAccountShowPage) {
            NewAccountPageTrigger.instance.setNewAccountShowPage(page)
        }
    }

    function submit() {
        const whenSuccess = (): void => {
            setSuccess(true)
        }
        const whenFail = (): void => {
            setSubmitWaiting(false)
            setSubmitFailed(true)
        }

        const validateField = (isValid: boolean, setMessage: (arg0: boolean) => void): boolean => {
            if (!isValid) {
                setMessage(true); // Show error message
                return false;
            }
            setMessage(false); // Hide error message
            return true;
        };

        const validateAllFields = (): boolean => {
            let validate = true;
            validate = validate && validateField(validateInput(firstName), setShowFirstNameMessage);
            validate = validate && validateField(validateInput(lastName), setShowLastNameMessage);

            // Validate individual fields and update their corresponding message visibility
            if (RegistrationData.instance.show_htn_med_question) {
                validate = validate && validateField(validateInput(takeBloodPressureMedicationValue), setShowBPMedicationMessage);
            }
            if (RegistrationData.instance.show_dm_med_question) {
                validate = validate && validateField(validateInput(takeDiabetesMedicationValue), setShowDMMedicationMessage);
            }
            validate = validate && validateField(validateInput(phoneModel), setShowPhoneModelMessage);
            if (RegistrationData.instance.show_wearable_device_question) {
                validate = validate && validateField(validateInput(deviceType), setShowDeviceTypeMessage);
            }
            if (RegistrationData.instance.show_bp_cuff_question) {
                validate = validate && validateField(validateInput(bloodPressureMonitor), setShowBloodPressureMonitorMessage);
            }
            if (RegistrationData.instance.show_glucometer_question) {
                validate = validate && validateField(validateInput(glucometer), setShowGlucometerMessage);
            }
            if (RegistrationData.instance.deviceType === "No" ||
                RegistrationData.instance.bloodPressureMonitor === "No" ||
                RegistrationData.instance.glucometer === "No") {
                validate = validate && validateField(validateInput(address), setShowAddressMessage);
                validate = validate && validateField(validateInput(addressCity), setShowAddressCityMessage);
                validate = validate && validateField(validateInput(addressState), setShowAddressStateMessage);
                validate = validate && validateField(validateInput(addressZipcode), setShowAddressZipcodeMessage);
            }
            return validate;
        };

        // Example usage
        const isValid = validateAllFields();
        if (isValid) {
            setSubmitWaiting(true)
            RegistrationData.instance.submit(whenSuccess, whenFail)// Proceed with the next steps, e.g., form submission
        } else {
            // Handle validation errors
            return
        }
    }

    // Are you taking blood pressure medication?
    function renderBpMedicationQuestion(): JSX.Element | null {
        const htnMedicationQuestion: JSX.Element =
            <SingleSelectComponent
                question="Are you taking blood pressure medication?"
                options={binaryOptions}
                value={takeBloodPressureMedicationValue}
                onChange={(value) => {
                    setTakeBloodPressureMedicationValue(value);
                    RegistrationData.instance.bloodPressureMedication = value;
                }}
                showMessage={showBPMedicationMessage}
            />;
        if (RegistrationData.instance.show_htn_med_question) {
            return htnMedicationQuestion
        } else {
            return null
        }
    }

    // "Are you taking diabetes medication?"
    function renderDmMedicationQuestion(): JSX.Element | null {
        const dmMedicationQuestion: JSX.Element = <SingleSelectComponent
            question="Are you taking diabetes medication?"
            options={binaryOptions}
            value={takeDiabetesMedicationValue}
            onChange={(value) => {
                setTakeDiabetesMedicationValue(value)
                RegistrationData.instance.dmMedication = value
            }}
            showMessage={showDMMedicationMessage}
        />;
        if (RegistrationData.instance.show_dm_med_question) {
            return dmMedicationQuestion
        } else {
            return null;
        }
    }


    function renderPhoneTypeQuestion(): JSX.Element {
        const phoneTypeQuestion: JSX.Element = <SingleSelectComponent
            question="Do you have an Apple or Android phone?"
            options={phoneList}
            value={phoneModel}
            onChange={(value) => {
                setPhoneModel(value as 'Apple' | 'Android')
                RegistrationData.instance.phoneType = value as 'Apple' | 'Android'
            }}
            showMessage={showPhoneModelMessage}
        />
        return phoneTypeQuestion;
    }

    // "Do you have an Apple Watch or Fitbit that you will use for the program?"
    function renderDeviceTypeQuestion(): JSX.Element | null {
        const deviceTypeList = [
            {
                value: 'Apple Watch',
                label: 'Yes, I will use my Apple Watch',
                show: RegistrationData.instance.phoneType === "Apple"
            },
            {value: 'Fitbit', label: 'Yes, I will use my Fitbit', show: true},
            {value: 'No', label: 'No, please send me a Fitbit', show: RegistrationData.instance.provide_device_option},
            {
                value: 'opt-out',
                label: "No, I prefer not to wear a device and will self-report my exercise and sleep data",
                show: RegistrationData.instance.opt_out_wearable_option
            }]

        const deviceTypeQuestion: JSX.Element = <SingleSelectComponent
            question="Do you have an Apple Watch or Fitbit that you will use for the program?"
            options={deviceTypeList}
            value={deviceType}
            onChange={(value) => {
                setDeviceType(value as 'Apple Watch' | 'Fitbit' | 'No' | 'opt-out')
                RegistrationData.instance.deviceType = value as 'Apple Watch' | 'Fitbit' | 'No' | 'opt-out'
            }}
            showMessage={showDeviceTypeMessage}
        />;
        if (RegistrationData.instance.show_wearable_device_question) {
            return deviceTypeQuestion
        } else {
            return null
        }
    }

    // "Do you own a Bluetooth-enabled Omron or iHealth blood pressure monitor?"
    function renderBPcuffQuestion(): JSX.Element | null {
        const bpCuffOptions = [
            {value: 'Omron', label: 'Yes, I will use my Omron monitor', show: true},
            {value: 'iHealth', label: 'Yes, I will use my iHealth monitor', show: true},
            {
                value: 'No',
                label: 'No, please send me an Omron monitor',
                show: RegistrationData.instance.provide_bp_cuff_option
            },
            {
                value: 'Manual',
                label: 'No, but I will use another blood pressure monitor and self-report my readings',
                show: !RegistrationData.instance.bp_cuff_require_bluetooth_option
            }
        ]
        const bpCuffQuestion: JSX.Element = <SingleSelectComponent
            question="Do you have a Bluetooth-enabled Omron or iHealth blood pressure monitor that you will use for the program?"
            options={bpCuffOptions}
            value={bloodPressureMonitor}
            onChange={(value) => {
                setBloodPressureMonitor(value)
                RegistrationData.instance.bloodPressureMonitor = value
            }}
            showMessage={showBloodPressureMonitorMessage}
        />;
        if (RegistrationData.instance.show_bp_cuff_question) {
            return bpCuffQuestion
        } else {
            return null;
        }
    }


    // "Do you have a finger-prick glucose monitor or a continuous glucose monitor (CGM) that you will use for the program?"
    function renderGlucometerQuestion(): JSX.Element | null {
        const glucometerOptions = [
            {value: 'FreeStyle Libre', label: 'Yes, I will use my FreeStyle Libre CGM', show: true},
            {
                value: 'Dexcom',
                label: 'Yes, I will use my Dexcom CGM',
                show: RegistrationData.instance.phoneType === 'Apple'
            },
            {value: 'Manual', label: 'Yes, I will use my finger-prick glucose monitor', show: true},
            {
                value: 'No',
                label: 'No, please send me a FreeStyle Libre CGM',
                show: RegistrationData.instance.provide_glucometer_option
            }
        ]
        const glucometerQuestion: JSX.Element = <SingleSelectComponent
            question="Do you have a continuous glucose monitor (CGM) or a finger-prick glucose monitor that you will use for the program?"
            options={glucometerOptions}
            value={glucometer}
            onChange={(value) => {
                setGlucometer(value)
                RegistrationData.instance.glucometer = value
            }}
            showMessage={showGlucometerMessage}
        />;
        if (RegistrationData.instance.show_glucometer_question) {
            return glucometerQuestion
        } else {
            return null
        }
    }


    // Please enter your mailing address so that we can ship you the device(s)
    function renderAddressQuestion(): JSX.Element | null {
        const addressQuestion: JSX.Element = <SpaceBetween size='xxxs' direction='vertical'>
            <SpaceBetween size='m' direction='horizontal'>
                <div>Please enter your mailing address so that we can ship you the device(s)</div>
            </SpaceBetween>
            <TextInput
                value={address}
                onChange={(value) => {
                    setAddress(value);
                    RegistrationData.instance.address = value;
                }}
                placeholder="Address"
                showMessage={showAddressMessage}
            />
            <TextInput
                value={addressCity}
                onChange={(value) => {
                    setAddressCity(value);
                    RegistrationData.instance.addressCity = value;
                }}
                placeholder="City"
                showMessage={showAddressCityMessage}
            />
            <TextInput
                value={addressState}
                onChange={(value) => {
                    setAddressState(value);
                    RegistrationData.instance.addressState = value;
                }}
                placeholder="State"
                showMessage={showAddressStateMessage}
            />
            <TextInput
                value={addressZipcode}
                onChange={(value) => {
                    setAddressZipcode(value);
                    RegistrationData.instance.addressZipcode = value;
                }}
                placeholder="Zipcode"
                type="number"
                showMessage={showAddressZipcodeMessage}
            />
        </SpaceBetween>
        if (RegistrationData.instance.deviceType === "No" ||
            RegistrationData.instance.bloodPressureMonitor === "No" ||
            RegistrationData.instance.glucometer === "No") {
            return addressQuestion
        } else {
            return null;
        }

    }

    return <div>
        {
            success &&
            <div>
                <SpaceBetween size='xl' direction='vertical'>
                    <div className='page-container'>
                        <div>You have successfully registered your account.</div>
                        <div>Please see your email for the next step.</div>
                    </div>
                </SpaceBetween>
            </div>
        }
        {!success && <div>
            <SpaceBetween size='xl' direction='vertical'>
                <SpaceBetween size='xxxs' direction='vertical'>
                    <div>Name</div>
                    <SpaceBetween size='xxxs' direction='horizontal'>
                        <TextInput
                            value={firstName}
                            onChange={(value) => {
                                setFirstName(value);
                                RegistrationData.instance.firstName = value;
                            }}
                            placeholder="First Name"
                            showMessage = {showFirstNameMessage}/>
                        <TextInput
                            value={lastName}
                            onChange={(value) => {
                                setLastName(value)
                                RegistrationData.instance.lastName = value
                            }}
                            placeholder="Last Name"
                            showMessage = {showLastNameMessage}/>
                    </SpaceBetween>
                </SpaceBetween>

                {renderBpMedicationQuestion()}
                {renderDmMedicationQuestion()}
                {renderPhoneTypeQuestion()}
                {renderDeviceTypeQuestion()}
                {renderBPcuffQuestion()}
                {renderGlucometerQuestion()}
                {renderAddressQuestion()}

                <div className='line-align-center'>
                    <Button className='line-items-padding' onClick={() => {
                        gotoPage(PagesEnum.First)
                    }} disabled={submitWaiting}>Back</Button>
                    <Button variant='primary' className='line-items-padding' onClick={submit} disabled={submitWaiting}>
                        {
                            submitWaiting && <Spinner/>
                        }
                        Submit
                    </Button>
                </div>

                {
                    submitFailed && <p className='tip-text'>Submission Failed.</p>
                }
            </SpaceBetween>
        </div>
        }
    </div>
}
